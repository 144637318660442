import { computed, unref } from 'vue'
import { groupBy, sumBy, update } from 'lodash-es'
import { AMOUNT_TYPE } from '@tenant/utils/constants'
import BigNumber from 'bignumber.js'

export const useNetGross = (
    items,
    taxType,
    amountField = 'amount',
    taxField = 'tax_rule_id'
) => {
    const { taxRules } = useTaxRules()
    const totalNet = computed(() => {
        const fieldItems = unref(items) || []
        const taxTypeUnref = unref(taxType)
        if (taxTypeUnref === AMOUNT_TYPE.NET) {
            return sumBy(fieldItems, (item) => {
                return +item?.value?.[amountField] || 0
            })
        }

        return sumBy(fieldItems, (item) => {
            const amount = +item?.value?.[amountField] || 0

            const taxRule = taxRules.value?.find(
                (i) => i.id === item?.value?.[taxField]
            )

            if (!taxRule) {
                return amount
            }

            return amount / (1 + taxRule.tax_rate / 100)
        })
    })

    const vatAmounts = computed(() => {
        const fieldItems = unref(items) || []

        if (!fieldItems.length) return null

        // Group all the line times by VAT rate
        const taxRates = groupBy(fieldItems, 'value.tax_rule_id')

        // Update VAT amount by VAT rate
        for (const key of Object.keys(taxRates)) {
            const taxRule = taxRules.value?.find((i) => i.id === key)

            update(taxRates, key, (items) => {
                return {
                    taxRule: taxRule,
                    vat: getTotalVAT(items),
                }
            })
        }

        return taxRates
    })

    const getTotalVAT = (items) => {
        const taxTypeUnref = unref(taxType)
        const total = sumBy(items, (item) => {
            const taxRule = taxRules.value?.find(
                (i) => i.id === item.value?.tax_rule_id
            )

            if (!taxRule) {
                return 0
            }

            if (taxTypeUnref === AMOUNT_TYPE.NET) {
                return item.value.amount * (taxRule.tax_rate / 100)
            }

            return (
                item.value.amount -
                item.value.amount / (1 + taxRule.tax_rate / 100)
            )
        })

        return BigNumber(total).toNumber()
    }

    const totalGross = computed(() => {
        const fieldItems = unref(items) || []
        const taxTypeUnref = unref(taxType)

        if (taxTypeUnref === AMOUNT_TYPE.GROSS) {
            return sumBy(fieldItems, (item) => +item?.value?.[amountField] || 0)
        }

        return sumBy(fieldItems, (item) => {
            const amount = +item?.value?.[amountField] || 0
            const taxRule = taxRules.value?.find(
                (i) => i.id === item.value?.[taxField]
            )

            return amount * (1 + (taxRule?.tax_rate ?? 0) / 100)
        })
    })

    const totalVat = computed(() => {
        return new BigNumber(totalGross.value).minus(totalNet.value).toNumber()
    })

    return {
        totalGross,
        totalVat,
        totalNet,
        vatAmounts,
    }
}
